import { baseURL, get, post } from './request'
import { Dialog, Toast } from 'vant'

/**
 * 日期时间格式化
 * @param {number} ts
 * @param {string} format
 * @returns {string}
 */
export function dateFormat(ts, format = 'yyyy-MM-dd') {
  const fmtArr = ['yyyy', 'MM', 'dd', 'hh', 'mm', 'ss']
  const returnArr = []
  const dt = new Date(String(ts).length === 10 ? ts * 1000 : ts)
  const addZero = (n) => { return n < 10 ? ('0' + n) : n }
  returnArr.push(dt.getFullYear())
  returnArr.push(addZero(dt.getMonth() + 1))
  returnArr.push(addZero(dt.getDate()))
  
  returnArr.push(addZero(dt.getHours()))
  returnArr.push(addZero(dt.getMinutes()))
  returnArr.push(addZero(dt.getSeconds()))
  
  for (const i in returnArr) {
    format = format.replace(fmtArr[i], returnArr[i])
  }
  return format
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(ts) {
  const time = String(ts).length === 10 ? ts * 1000 : ts
  const d = new Date(time)
  const now = Date.now()
  
  const diff = (now - d) / 1000
  
  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  
  const fmtNum = function (n) { return n < 10 ? '0' + n : n }
  
  return (
    fmtNum(d.getMonth() + 1) + '-' + fmtNum(d.getDate()) + ' ' + fmtNum(d.getHours()) + ':' + fmtNum(d.getMinutes())
  )
}

/**
 * 生成UUID
 * @returns {String}
 */
export function generateUUID() {
  let d = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

/**
 * 上传文件到OSS
 * @params type 传1的话 就会保留 0的话会定时删除
 * @returns {String}
 */
export function ossUploadFile(file, type = 0) {
  console.log(file)
  return new Promise((resolve, reject) => {
    get('/FileUpload/GetOssParam')
      .then(res => {
        post(baseURL + '/FileUpload/UploadFile', {
          name: res.guid,
          key: res.key,
          policy: res.encodedPolicy,
          OSSAccessKeyId: res.ossAccessKeyId,
          success_action_status: 200,
          // callback: res.callback,
          signature: res.signature,
          file: file,
          type: type
        }, {
          isFile: true,
          header: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'UserToken': localStorage.getItem('Token'),
            'UserTime': (new Date()).getTime()
          },
          onUploadProgress: (progress) => {
            const n = Math.round(progress.loaded / progress.total * 100) + '%'
            Toast.loading({
              message: '上传中' + n,
              forbidClick: true
            })
          }
        })
          .then((uploadFileRes) => {
            if (uploadFileRes) {
              resolve(uploadFileRes.Url)
            } else {
              Dialog.alert({
                title: '上传失败！',
                message: JSON.parse(uploadFileRes.data).message
              })
              reject('上传失败')
            }
          })
      })
  })
}

export function debounce(fn, delay) {
  let time
  return (arg) => {
    if (time) clearTimeout(time)
    time = setTimeout(() => {
      fn(arg)
    }, delay)
  }
}

export function throttle(fn, wait) {
  let previous = 0
  return (arg) => {
    let now = Date.now()
    if (now - previous > wait) {
      fn(arg)
      previous = now
    }
  }
}

export function getParams(url) {
  let obj = Object.create(null)
  url.replace(/([^?&=]+)=([^&#]+)/g, (_, k, v) => {
    obj[k] = v
  })
  return obj
}
