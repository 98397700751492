<template>
  <div class="page">
    <div class="s1">
      <draggable v-model="contentMsgs"
                 handle=".drag-item"
                 animation="300"
                 :force-fallback="true">
        <transition-group>
          <div v-for="(item,index) in contentMsgs"
               :key="index"
               class="item flex p-card">
            <div class="drag-item pointer">
              <div class="icon-1" />
            </div>
            <div class="flex-1">
              <msg-box :data="item" />
            </div>
            <div class="divider-v ml-1" />
            <div class="ml-1"
                 title="编辑"
                 @click="editClick(item,index)">
              <div class="p-icon-edit" />
            </div>
            <div class="ml-1"
                 title="删除"
                 @click="contentMsgs.splice(index,1)">
              <div class="p-icon-delete" />
            </div>
          </div>
        </transition-group>
      </draggable>
      <template v-if="contentMsgs.length<5">
        <div class="p-card add"
             @click="addClick()">添加</div>
        <div class="text-center color-gray mt-2">（还可添加{{ 5-contentMsgs.length }}条回复）</div>
      </template>
    </div>

    <div class="x-padding-bottom" />
    <div class="p-footer">
      <div class="flex h-between flex-1">
        <div class="flex  flex-right">
          <button class="btn auto gray"
                  @click="submit(0)">关闭</button>
          <button class="btn auto fill ml-3"
                  @click="submit(1)">保存</button>
        </div>
      </div>
    </div>

    <select-source-type :data="contentMsgs"
                        :show="dialog1"
                        @update="contentMsgs = $event"
                        @close="dialog1=false" />

  </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import msgBox from '@/components/msg-box/index.vue'
import selectSourceType from '@/components/select-source-type/index.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  components: { msgBox, selectSourceType, draggable: VueDraggableNext },
  data () {
    return {
      randomSend: true,
      dialog1: false,
      contentMsgs: [],
      from: ''
    }
  },
  computed: {
    ...mapGetters(['globalData'])
  },
  watch: {
    '$route' (to, from) {
      if (from.path === '/keywordsEdit') {
        // this.$store.commit('resetGlobalData')
        this.getData()
      }
      // if (to.path === '/keywordsEdit') {
      //   this.$store.commit('resetGlobalData')
      // }
      this.from = from.path
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      const _this = this
      _this.contentMsgs = _this.globalData.contentList ? _this.globalData.contentList : []

      console.log(_this.contentMsgs)
    },
    addClick () {
      this.dialog1 = true
      this.$store.commit('setGlobalData', {
        isEdit: false
      })
    },
    editClick (item, index) {
      this.dialog1 = true
      this.$store.commit('setGlobalData', {
        ...item,
        isEdit: true,
        editIndex: index
      })
    },
    submit (n) {
      this.$store.commit('setGlobalData', {
        contentList: this.contentMsgs
      })
      // this.$router.go(-1)
      this.$router.replace({ path: '/keywordsEdit' })
      //  this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
